import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Location, LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {push} from 'connected-react-router';
import {ApplicationState} from '../../store';
import {closePopup, PopupType} from '../../store/popup';
import {RequestStatus, RequestStatusType} from '../../store/shared/types';
import {format} from '../../utils/date-helper';
import {buildSearchParameters} from '../../utils/query-parameter-helpers';
import WidgetTable, {WidgetColumnConfig, WidgetTableColumnType} from '../widget-table/widget-table';
import styles from './tire-service-details-popup.module.scss';
import {getTireServiceRequest, TireServiceDetails} from '../../store/jobs';
import {ServiceProviderGroupWithConfiguration} from '../../store/service-provider-groups';
import JobsApi from '../../api/jobs-api';
import {generateUUID} from '../../utils/uuid-helpers';
import {addToast, defaultRemoveAfterMilliseconds, ToastType} from '../../store/toast';
import {updateVehicleDetailsQueryParameters} from '../vehicle-details-popup/vehicle-details-popup';
import {getJobTypeLabel} from "../../utils/translations/job-type-translation-helper";

class TireServiceDetailsPopup extends Component<AllProps> {
    constructor(props) {
        super(props);

        const {jobId, serviceProviderGroup, dispatchGetTireServiceDetails} = this.props;
        if (jobId && serviceProviderGroup) {
            dispatchGetTireServiceDetails({jobId,serviceProviderGroupId:serviceProviderGroup.id,language:serviceProviderGroup.activeLanguage.type});
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {jobId, serviceProviderGroup, dispatchGetTireServiceDetails} = this.props;
        if (jobId && serviceProviderGroup && serviceProviderGroup !== prevProps.serviceProviderGroup) {
            dispatchGetTireServiceDetails({jobId,serviceProviderGroupId:serviceProviderGroup.id,language:serviceProviderGroup.activeLanguage.type});
        }
    }

    public render(): JSX.Element {
        const {t, requestStatus, tireService} = this.props;

        const taskList = tireService ? tireService.taskList : [];
        const damageDetails = tireService ? tireService.damageDetails : [];

        const taskListConfigs: WidgetColumnConfig[] = [
            {title: t('Axle'), property: 'axle', type: WidgetTableColumnType.text, width: '10%'},
            {title: t('Wheel'), property: 'wheel', type: WidgetTableColumnType.text, width: '12%'},
            {title: t('Description'), property: 'description', type: WidgetTableColumnType.text, width: '20%'},
            {title: t('Serial Number'), property: 'serialNumber', type: WidgetTableColumnType.text, width: '13%'},
            {title: t('Task Reason'), property: 'taskReason', type: WidgetTableColumnType.text, width: '15%'},
            {title: t('Tread Depth'), property: 'treadDepth', type: WidgetTableColumnType.text, width: '10%'},
            {title: t('Pressure Initial'), property: 'pressureInitial', type: WidgetTableColumnType.text, width: '10%'},
            {title: t('Pressure Adjust'), property: 'pressureAdjust', type: WidgetTableColumnType.text, width: '10%'},
        ];

        const damageDetailsConfigs: WidgetColumnConfig[] = [
            {title: t('Serial Number'), property: 'serialNumber', type: WidgetTableColumnType.text, width: '15%'},
            {title: t('Damage Area'), property: 'damageArea', type: WidgetTableColumnType.text, width: '20%'},
            {title: t('Damage Type'), property: 'damageType', type: WidgetTableColumnType.text, width: '20%'},
            {title: t('Tread Depth'), property: 'treadDepth', type: WidgetTableColumnType.text, width: '15%'},
        ]

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupContent}>
                    <div className={styles.contentTitle}>
                        <div className={styles.type}>{t('Job')}</div>
                        <div className={styles.id}>{tireService ? tireService.reference : ''}</div>
                    </div>
                    <div className={styles.contentBody}>
                        {tireService ? (
                            <div className={styles.infoContainers}>
                                <div className={styles.infoContainer}>
                                    {tireService.jobDate ? (
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Job Date')}</div>
                                            <div className={styles.value}>
                                                {format(new Date(tireService.jobDate), 'MMM D, YYYY')}
                                            </div>
                                        </div>
                                    ) : ( 
                                        ''
                                    )}
                                    {tireService.isEjob && tireService.ejobId ? (
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('eJob sheet')}</div>
                                            <div className={styles.action}
                                                 onClick={() => this.onDownloadEJobSheet(tireService?.ejobId, tireService?.reference)}>{t('Download')}</div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                   {tireService.hasAttachments ? (
                                        <div className={styles.field}>
                                            <div className={styles.label}>{`${t('Attachments')}`}</div>
                                            <div className={styles.action}
                                                 onClick={() => this.onDownloadAttachments(tireService?.reference)}>{t('Download')}</div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className={styles.infoContainer}>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('License Plate')}</div>
                                        <div className={styles.action} onClick={() => this.onVehicleClick(tireService?.vehicleId)}>{tireService.licensePlate}</div>
                                    </div>
                                    {tireService.odometerReading ? (
                                        <div className={styles.field}>
                                            <div className={styles.label}>{t('Odometer Reading')}</div>
                                            <div className={styles.value}>{tireService.odometerReading} KM</div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className={styles.infoContainer}>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('Service Provider')}</div>
                                        <div className={styles.value}>{tireService.serviceProvider}</div>
                                    </div>
                                    <div className={styles.field}>
                                        <div className={styles.label}>{t('Service Type')}</div>
                                        <div className={styles.value}>{getJobTypeLabel(tireService.jobType, t)}</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className={styles.jobsTitle}>{t('Task List')}</div>
                        {tireService && tireService.taskList.length > 0 ? (
                            <div className={styles.configurationContainer}>
                                <div className={styles.configurationDetails}>
                                    <WidgetTable config={{columns: taskListConfigs, hideLastRecordBorder: true}}
                                                 data={taskList}/>
                                </div>
                            </div>
                        ) : requestStatus && requestStatus.type === RequestStatusType.InProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} style={{margin: '0 10px 0 0'}} spin/>{' '}
                                {t('loading')}
                            </div>
                        ) : (
                            <div>{t('No tasks found')}</div>
                        )}
                        <div className={styles.jobsTitle}>{t('Damage Details')}</div>
                        {tireService && tireService.damageDetails.length > 0 ? (
                            <div className={styles.configurationContainer}>
                                <div className={styles.configurationDetails}>
                                    <WidgetTable config={{columns: damageDetailsConfigs, hideLastRecordBorder: true}}
                                                 data={damageDetails}/>
                                </div>
                            </div>
                        ) : requestStatus && requestStatus.type === RequestStatusType.InProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} style={{margin: '0 10px 0 0'}} spin/>{' '}
                                {t('loading')}
                            </div>
                        ) : (
                            <div>{t('No damage details found')}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private onVehicleClick(vehicleId: string) {
        const {dispatchClosePopup, dispatchNavigateTo, currentLocation} = this.props;
        if (vehicleId) {
            const queryParameters = updateVehicleDetailsQueryParameters(vehicleId, currentLocation.search);
            dispatchClosePopup();
            dispatchNavigateTo({search: queryParameters});
        }
    }

    private onDownloadEJobSheet(ejobId: string, reference: string): void {
        const {serviceProviderGroup, dispatchAddToast, t} = this.props;

        if (ejobId) {
            JobsApi.downloadEjobSheet(serviceProviderGroup!.id, ejobId, reference)
                .then((success) => {
                    if (!success) {
                        dispatchAddToast({
                            id: generateUUID(),
                            messages: [t('This document is not yet available.')],
                            type: ToastType.Error,
                            removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
                        })
                    }
                });
        }
    }

    private onDownloadAttachments( reference: string): void {
        const {serviceProviderGroup,dispatchAddToast,t} = this.props;

        if (reference) {
            JobsApi.downloadAttachments(serviceProviderGroup!.id, reference)
                .then((success) => {
                    if (!success) {
                        dispatchAddToast({
                            id: generateUUID(),
                            messages: [t('There are no attachments available.')],
                            type: ToastType.Error,
                            removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
                        })
                    }
                });
        }
    }
}

const mapStateToProps = ({router, jobs, authentication}: ApplicationState): PropsFromState => ({
    currentLocation: router.location,
    tireService: jobs.tireService,
    requestStatus: jobs.tireServiceRequestStatus,
    serviceProviderGroup: authentication.serviceProviderGroup,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchGetTireServiceDetails: (request) =>
        dispatch(getTireServiceRequest(request)),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TireServiceDetailsPopup));

interface OwnProps {
    jobId?: string;
}

interface PropsFromState {
    currentLocation: Location;
    requestStatus?: RequestStatus;
    tireService?: TireServiceDetails;
    serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
}

interface PropsFromDispatch {
    dispatchGetTireServiceDetails: typeof getTireServiceRequest;
    dispatchAddToast: typeof addToast;
    dispatchNavigateTo: (LocationDescriptorObject) => void;
    dispatchClosePopup: typeof closePopup;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

export function updateTireServiceQueryParameters(jobId: string, search: string): string {
    return buildSearchParameters(
        {
            jobId,
            popup: PopupType.TireServiceDetails,
            purchasingDocumentId: undefined,
            serviceProviderId: undefined,
            vehicleId: undefined,
        },
        search,
    );
}
