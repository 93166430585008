import {
    EjobSheet,
    Job,
    JobAttachments,
    JobContract,
    JobsExplorerRequest,
    TireServiceDetails, TireServiceDetailsRequest,
} from '../store/jobs';
import {ApiResult} from '../types/api-result';
import {Http} from '../http/http';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {Paged} from '../store/shared/types';
import {LanguageRequest} from '../store/analytics';
import {performBase64Download} from '../utils/download-helper';

class JobsApi {
    public static async getJobs(request: JobsExplorerRequest, serviceProviderGroupId: string): Promise<ApiResult<Paged<Job>>> {
        const pagedJobs = await Http.post<any, Paged<JobContract>>(ApiUrl.Jobs, request, new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());

        return {data: {pageData: pagedJobs.pageData.map(j => mapToJob(j)), totalCount: pagedJobs.totalCount}};
    }

    public static async getTireService(request: TireServiceDetailsRequest): Promise<ApiResult<TireServiceDetails>> {
        const result = await Http.post<LanguageRequest,TireServiceDetails>(ApiUrl.TireServiceDetails,{language:request.language},
            new UrlParamBuilder().addServiceProviderGroupId(request.serviceProviderGroupId).addJobId(request.jobId).build());
        return {data: result};
    }

    public static async downloadEjobSheet(serviceProviderGroupId: string, ejobId: string, reference: string): Promise<boolean> {
        const result = await Http.get<EjobSheet>(ApiUrl.EjobSheet,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).addEJobId(ejobId).build());
        if (result.available) {
            performBase64Download(result.data, 'pdf', `eJob Sheet - ${reference}.pdf`);
            return true;
        } else {
            return false;
        }
    }

    public static async downloadAttachments(serviceProviderGroupId: string, jobReference: string): Promise<boolean> {
        const result = await Http.get<JobAttachments>(ApiUrl.JobAttachments,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).addJobReference(jobReference).build());
        if (result.available) {
            for (let i = 0; i < result.data.documents.length; i++){
                const doc = result.data.documents[i];
                const zipData = doc.base64Content;
                performBase64Download(zipData, 'pdf', doc.name);
            }
            return true;
        } else {
            return false;
        }
    }
}

function mapToJob(contract: JobContract): Job {
    return {...contract, startDate: new Date(contract.startDate), endDate: new Date(contract.endDate)};
}

export default JobsApi;
