import {RuntimeConfig} from '../config/runtime-config';

export class ApiEndpoint {
    constructor(public path: string, public apiType: ApiType) {
    }
}

export enum ApiType {
    Root = 'Root',
    Dashboard = 'Dashboard',
    Reports = 'Reports',
}

export class ApiUrl {
    // ROOT API
    public static MaintenanceStatus: ApiEndpoint = new ApiEndpoint('/maintenance/status', ApiType.Root);

    // DASHBOARD API
    public static DataStatus: ApiEndpoint = new ApiEndpoint('/data/status', ApiType.Dashboard);

    public static CurrentUser: ApiEndpoint = new ApiEndpoint('/users/current', ApiType.Dashboard);

    public static CurrentUserSettings = new ApiEndpoint('/users/current/settings', ApiType.Dashboard);

    public static CurrentUserPrivacyPolicy: ApiEndpoint = new ApiEndpoint('/users/current/privacy-policy', ApiType.Dashboard);

    public static Users: ApiEndpoint = new ApiEndpoint('/users', ApiType.Dashboard);

    public static UsersSearch: ApiEndpoint = new ApiEndpoint('/users/search', ApiType.Dashboard);

    public static UsersGet: ApiEndpoint = new ApiEndpoint('/users/{user-id}', ApiType.Dashboard);

    public static UsersDelete: ApiEndpoint = new ApiEndpoint('/users/{user-id}', ApiType.Dashboard);

    public static UsersUserStructure = new ApiEndpoint('/users/user-structure', ApiType.Dashboard);

    public static Dashboards: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/dashboards', ApiType.Dashboard);

    public static ServiceProviders: ApiEndpoint = new ApiEndpoint('/service-providers', ApiType.Dashboard);

    public static ServiceProviderStructure: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/service-provider-structure', ApiType.Dashboard);

    public static ServiceProvidersStructure: ApiEndpoint = new ApiEndpoint('/service-provider-groups/service-provider-structure', ApiType.Dashboard);

    public static ServiceProviderMappings: ApiEndpoint = new ApiEndpoint('/service-provider-mappings/{service-provider-group-id}', ApiType.Dashboard);

    public static ServiceProvidersForGroup: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/service-providers', ApiType.Dashboard);

    public static ServiceProviderDetails: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/service-providers/{service-provider-id}', ApiType.Dashboard);

    public static ServiceProviderGroups: ApiEndpoint = new ApiEndpoint('/service-provider-groups', ApiType.Dashboard);

    public static ServiceProviderGroupsSearch: ApiEndpoint = new ApiEndpoint('/service-provider-groups/search', ApiType.Dashboard);

    public static ServiceProviderGroup: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}', ApiType.Dashboard);

    public static ServiceProviderGroupCreate: ApiEndpoint = new ApiEndpoint('/service-provider-groups', ApiType.Dashboard);

    public static Search: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/search', ApiType.Dashboard);

    public static EjobSheet: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/ejobs/{e-job-id}/job-sheet', ApiType.Dashboard);

    public static JobAttachments = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/jobs/{job-reference}/attachments', ApiType.Dashboard);

    public static TotalInvoiced: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/purchasing/total-invoiced', ApiType.Dashboard);

    public static PurchasingDistribution: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/purchasing/distribution', ApiType.Dashboard);

    public static PurchasingEvolution: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/purchasing/evolution', ApiType.Dashboard);

    public static JobsTotalCount: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/jobs/total-count', ApiType.Dashboard);

    public static InspectionsTotalCount: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/inspections/total-count', ApiType.Dashboard);

    public static InspectionRatio: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/inspections/ratio', ApiType.Dashboard);

    public static JobsDistribution: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/jobs/distribution', ApiType.Dashboard);

    public static JobsEjobUtilization: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/jobs/ejob-utilization', ApiType.Dashboard);

    public static JobsProcessTime: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/jobs/process-time', ApiType.Dashboard);

    public static JobsAcceptanceTime: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/jobs/acceptance-time', ApiType.Dashboard);

    public static TopSoldTos = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/sold-tos/top-20', ApiType.Dashboard);

    public static Jobs: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/jobs/search', ApiType.Dashboard);

    public static ServiceProvidersMonthlyOverview: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/service-providers/monthly-overview', ApiType.Dashboard);

    public static TireServiceDetails: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/{job-id}/tire-service-details', ApiType.Dashboard);

    public static TiresRetreadRatio: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/retread-ratio', ApiType.Dashboard);

    public static TiresECasingReturnsRatio: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/e-casing-return-ratio', ApiType.Dashboard);

    public static TiresCorporateRatio: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/corporate-fitment-ratio', ApiType.Dashboard);

    public static TiresAverageTreadDepth: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/average-tread-depth', ApiType.Dashboard);

    public static TiresRegrooveRatio: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/regroove-ratio', ApiType.Dashboard);

    public static TiresRegrooveCount: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/regroove-count', ApiType.Dashboard);

    public static TiresPWTStockAge: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/pwt-stock-age', ApiType.Dashboard);

    public static TiresPWTCycleTime: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/pwt-cycle-time', ApiType.Dashboard);

    public static TiresPWTCurrentStock: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/pwt-current-stock', ApiType.Dashboard);

    public static TiresFitmentsCount: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/fitments-count', ApiType.Dashboard);

    public static TiresFitmentsDistribution: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/fitments-distribution', ApiType.Dashboard);

    public static Vehicle: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/vehicles/{vehicle-id}', ApiType.Dashboard);

    public static TireBrandDistribution: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/brand-distribution', ApiType.Dashboard);

    public static CorporateFitmentDistribution: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/analytics/tires/corp-fit-distribution', ApiType.Dashboard);

    public static SoldTos: ApiEndpoint = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/sold-tos', ApiType.Dashboard);

    // REPORTS API

    public static ReportConfigurations = new ApiEndpoint('/report-configurations', ApiType.Reports);

    public static ReportsReportInfo = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/{report-id}', ApiType.Reports);

    public static ReportsDownloadUrl = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/{report-id}/url', ApiType.Reports);

    public static ReportsInspectionDetailsReport = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/inspection-details-report', ApiType.Reports);

    public static ReportsCustomerServiceLocationsReport = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/customer-service-locations-report', ApiType.Reports);

    public static ReportsTCUSensorFitmentsReport = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/tcu-sensor-fitments-report', ApiType.Reports);

    public static ReportsExtendedInspectionReport = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/extended-inspection-report', ApiType.Reports);

    public static ReportsVehiclesDueForInspectionDetailsReport = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/vehicles-due-for-inspection-details-report', ApiType.Reports);

    public static ReportsCasingsReport = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/casings-report', ApiType.Reports);

    public static ReportsTireStockReport = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/tire-stock-report', ApiType.Reports);

    public static ReportsTireStockCompanies = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/reports/tire-stock-report/companies', ApiType.Reports);

    public static ReportsScheduledReports = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/scheduled-reports', ApiType.Reports);

    public static ReportsAdminScheduledReports = new ApiEndpoint('/scheduled-reports', ApiType.Reports);

    public static ReportsScheduledReportsLog = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/scheduled-reports/{report-id}/log', ApiType.Reports);

    public static ReportsScheduledReportsUpdate = new ApiEndpoint('/service-provider-groups/{service-provider-group-id}/scheduled-reports/{report-id}', ApiType.Reports);
}

const apiVersion = 'v1';

export function buildBaseUrl(apiPrefix: ApiType): string {
    const apiUrl = getApiUrl(apiPrefix);
    return `${apiUrl}${apiVersion}`;
}

function getApiUrl(apiType: ApiType): string {
    switch (apiType) {
        case ApiType.Root:
            return RuntimeConfig.getRootApiURL();
        case ApiType.Dashboard:
            return RuntimeConfig.getDashboardApiURL();
        case ApiType.Reports:
            return RuntimeConfig.getReportsApiURL();
        default:
            throw new Error(`${apiType} is not a valid API type.`);
    }
}

export class UrlParamBuilder {
    private params: any = {};

    public addServiceProviderGroupId(id: string): UrlParamBuilder {
        this.params['service-provider-group-id'] = id;
        return this;
    }

    public addServiceProviderId(id: string): UrlParamBuilder {
        this.params['service-provider-id'] = id;
        return this;
    }

    public addUserId(id: string): UrlParamBuilder {
        this.params['user-id'] = id;
        return this;
    }

    public addJobId(id: string): UrlParamBuilder {
        this.params['job-id'] = id;
        return this;
    }

    public addJobReference(jobReference: string): UrlParamBuilder {
        this.params['job-reference'] = jobReference;
        return this;
    }

    public addEJobId(ejobId: string): UrlParamBuilder {
        this.params['e-job-id'] = ejobId;
        return this;
    }

    public addVehicleId(id: string): UrlParamBuilder {
        this.params['vehicle-id'] = id;
        return this;
    }

    public addPurchasingDocumentId(id: string): UrlParamBuilder {
        this.params['purchasing-document-id'] = id;
        return this;
    }

    public addReportId(id: string): UrlParamBuilder {
        this.params['report-id'] = id;
        return this;
    }

    public build(): any {
        return this.params;
    }
}
